import { FC } from 'react';
import cx from 'classnames';
import * as styles from './NewExperience.module.css';

export const NewExperience: FC = () => (
    <div className={cx('mt-24 flex flex-wrap gap-x-14 gap-y-5 justify-center md:justify-between text-[36px] md:text-[54px]', styles.textWrapper)}>
        <div>
            <span>Fast</span>
        </div>
        <div>
            <span>Supportive</span>
        </div>
        <div>
            <span>Reliable</span>
        </div>
        <div>
            <span>Real goods</span>
        </div>
        <div>
            <span>Decentralized</span>
        </div>
        <div>
            <span>For people</span>
        </div>
        <div className={cx(styles.expTitle, 'relative my-auto')}>
            <div className={styles.expGradient} />
            <span>New experience</span>
        </div>
    </div>
);
