.advantages {
    margin-bottom: 20px;
    position: relative;
    z-index: 3;
}
.advantagesWrapper {
    margin-bottom: 55px;
}
.advantagesTitle {
    font-weight: 700;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 1px;
    margin: 0 0 0 25px;
}
.advantagesBlock {
    display: flex;
    align-items: center;
}
.advantagesText {
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    /* margin: 0 0 5px 55px; */
}
