import { FC } from 'react';
import * as styles from './WhatIs.module.css';
import cx from 'classnames';
import { Title } from '../../../../../shared/components/Title/Title';
import { Gradient } from '../../../../../shared/components/Gradient/Gradient';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

export const WhatIs: FC = () => {
    const { isMobile } = useWindowDimensions();

    return (
        <div className={cx(styles.whatIs, 'mt-48')}>
            <Gradient type="4" />
            <Title type={isMobile ? "1" : "7"} tag="h1" className="text-center sm:mb-16">
                What is
                <br />
                ERA crypto?
            </Title>
            <div className={styles.whatIsText}>
                <b>ERA crypto</b> is <b>a decentralized</b>
                <br />
                <b>supply-and-demand marketplace.</b>
                <br />
                Your shopping experience from now has no borders.
                <br />
                <b>Shop and order what you like, limitlessly</b>.{' '}
            </div>
        </div>
    );
};

