import { FC } from 'react';
import { subscribeUrl } from '../../../../../constants';
import { ArrowButton } from '../../../../../shared/components/Arrow/Arrow';
import * as styles from './SubscriptionForm.module.css';

export const SubscriptionForm: FC = () => {
    return (
        <form className={styles.form} action={subscribeUrl} method="post">
            <input type="text" placeholder="Your e-mail" className={styles.input} name="EMAIL" />
            <div className={styles.subscribeBlock}>
                <input type="submit" value="Subscribe" className={styles.summitInput} />
                <ArrowButton type="submit" />
            </div>
        </form>
    );
};
