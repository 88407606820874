import { FC } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { Landing } from './components/Landing/Landing';
import { BottomNavigationMobile } from './components/Navigation/BottomNavigationMobile';
import { TermsOfUse } from './components/Documents/TermsOfUse';
import { PrivacyNotice } from './components/Documents/PrivacyNotice';

export const App: FC = () => (
    <>
        <BrowserRouter>
            <Switch>
                <Route exact path="/" component={Landing} />
                <Route exact path="/terms-of-use" component={TermsOfUse} />
                <Route exact path="/privacy-notice" component={PrivacyNotice} />
                <Route component={Landing} />
            </Switch>
        </BrowserRouter>
        <BottomNavigationMobile />
    </>
);
