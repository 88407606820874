import { FC } from 'react';
import { Title } from '../../../../../shared/components/Title/Title';
import { socialLinks } from './links';

export const Socials: FC = () => {
    return (
        <div>
            <div className="text-center mt-40 mb-20">
                <Title type="7">Our socials</Title>
            </div>
            <div className="flex gap-10 items-center justify-center">
                {socialLinks.map((item, index) => (
                    <div key={index}>
                        <a href={item.link} target="_blank" rel="noopener noreferrer">
                            <img src={String(item.image)} alt="img" />
                        </a>
                    </div>
                ))}
            </div>
        </div>
    );
};

