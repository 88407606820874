import { FC, ReactNode, useCallback, useState } from 'react';
import { Transition } from '@headlessui/react';
import { useSwipeable } from 'react-swipeable';
import { ArrowButton } from '../Arrow/Arrow';

export const SliderFrame: FC<{ texts: string[] }> = ({ texts }) => (
    <div className="flex flex-col">
        <div className="m-auto">
            {texts.map((text, index) => (
                <div key={index} className="text-center text-lg">
                    <span>{text}</span>
                </div>
            ))}
        </div>
    </div>
);

export const Slider: FC<{ frames: { frame: ReactNode }[] }> = ({ frames }) => {
    const [currentFrame, setCurrentFrame] = useState(0);

    const handleChangeFrame = useCallback(
        (modifier: number) => {
            const next = currentFrame + modifier;
            const lastFrame = frames.length - 1;

            if (next > lastFrame) {
                setCurrentFrame(0);
                return;
            }

            if (next < 0) {
                setCurrentFrame(lastFrame);
                return;
            }

            setCurrentFrame(next);
        },
        [currentFrame, frames]
    );

    const swipeable = useSwipeable({
        onSwipedRight: () => {
            handleChangeFrame(1);
        },
        onSwipedLeft: () => handleChangeFrame(-1),
    });

    return (
        <>
            <div
                {...swipeable}
                className="flex flex-nowrap justify-center items-center min-h-[180px] overflow-x-hidden"
            >
                {frames.map(({ frame }, index) => (
                    <Transition
                        key={index}
                        appear={true}
                        unmount={false}
                        show={index === currentFrame}
                        enter="transform transition ease-in-out duration-500"
                        enterFrom={`translate-x-60 opacity-0`}
                        enterTo={`translate-x-0 opacity-100`}
                        leave="hidden duration-500"
                        leaveFrom="opacity-100"
                        leaveTo={`opacity-0`}
                        as="div"
                    >
                        {frame}
                    </Transition>
                ))}
            </div>
            <div className="flex align-middle justify-center p-1.5 gap-2.5">
                <ArrowButton direction="left" onClick={() => handleChangeFrame(-1)} />
                <ArrowButton onClick={() => handleChangeFrame(1)} />
            </div>
        </>
    );
};
