import { FC } from 'react';
import classNames from 'classnames';
import arrow from '../../../../assets/svg/arrow-right.svg';
import * as styles from './Arrow.module.css';

type ArrowProps = {
    direction?: 'left';
};

type ArrowNavProps = {
    direction?: 'left';
    href: string;
};

type ArrowButtonProps = ArrowProps & {
    onClick?: () => void;
} & JSX.IntrinsicElements['button'];

const Arrow: FC<ArrowProps> = ({ direction }) => {
    return (
        <img
            src={String(arrow)}
            alt="arrow"
            className={classNames({
                [styles.left]: direction === 'left',
            })}
        />
    );
};

export const ArrowNav: FC<ArrowNavProps> = ({ direction, href, children }) => {
    return (
        <a href={href} target="_blank" rel="noreferrer noopener" className="flex justify-center items-center gap-2">
            {direction !== 'left' && children}
            <Arrow direction={direction} />
            {direction === 'left' && children}
        </a>
    );
};

export const ArrowButton: FC<ArrowButtonProps> = ({ direction, onClick, className, children }) => (
    <button onClick={onClick} className={classNames('flex justify-center items-center gap-2', className)}>
        <Arrow direction={direction} />
        {children}
    </button>
);
