import { FC, ReactNode } from 'react';
import cn from 'classnames';
import homeIcon from '../../../../assets/svg/homeNavIcon.svg';
import tradesIcon from '../../../../assets/svg/tradesNavIcon.svg';
import chatIcon from '../../../../assets/svg/chatNavIcon.svg';
import plusIcon from '../../../../assets/svg/plus.svg';
import dollar from '../../../../assets/svg/dollar.svg';
import { CircleButton } from '../../../shared/components/Button/CircleButton';
import { appUrl } from '../../../constants';
import useWindowDimensions from '../../hooks/useWindowDimensions';

type BottomNavigationItemProps = {
    icon: string | ReactNode;
    active?: boolean;
    onClick?: () => void;
    className?: string;
};

const BottomNavigationItem: FC<BottomNavigationItemProps> = ({ icon, active, onClick, className }) => (
    <div
        onClick={onClick}
        className={cn(
            'flex-1 text-center flex first:rounded-tl-xl last:rounded-tr-xl',
            active && 'border-t-2 border-t-white border-solid',
            className
        )}
    >
        <div className="m-auto">{icon}</div>
    </div>
);

export const BottomNavigationMobile: FC = () => {
    const { isMobile } = useWindowDimensions()

    if (!isMobile) {
        return null
    }

    const handleNavigate = (url: string) => window.open(url);

    return (
        <div className="flex w-full h-[80px]">
            <div className="w-full md:max-w-[680px] mx-auto flex w-full bg-[#131C28]">
                <BottomNavigationItem
                    onClick={() => handleNavigate(`${appUrl}/offers`)}
                    active={false}
                    icon={<img src={String(homeIcon)} alt="home" />}
                />
                <BottomNavigationItem
                    onClick={() => handleNavigate(`${appUrl}/deals`)}
                    active={false}
                    icon={<img src={String(tradesIcon)} alt="trades" />}
                />
                <BottomNavigationItem
                    className="mt-[-30px]"
                    onClick={() => handleNavigate(`${appUrl}/createOffer`)}
                    icon={
                        <CircleButton className="bg-gradient flex">
                            <img className="m-auto" src={String(plusIcon)} alt="create-offer" />
                        </CircleButton>
                    }
                />
                <BottomNavigationItem
                    onClick={() => handleNavigate(`${appUrl}/chats`)}
                    active={false}
                    icon={<img src={String(chatIcon)} alt="chat" />}
                />
                <BottomNavigationItem
                    onClick={() => handleNavigate(`${appUrl}/earn`)}
                    active={false}
                    icon={<img src={String(dollar)} alt="else" />}
                />
            </div>
        </div>
    );
};
