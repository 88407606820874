import { FC, ReactNode, createElement } from 'react';
import * as styles from './Title.module.css';
import classNames from 'classnames';

type Props = {
    children: ReactNode;
    tag?: 'h1' | 'h2' | 'h3';
    type?: '1' | '2' | '3' | '4' | '5' | '6' | '7';
    align?: 'left' | 'center' | 'right';
    isUppercase?: boolean;
    className?: string;
};

export const Title: FC<Props> = ({
    tag = 'h1',
    type = '1',
    align = 'center',
    children,
    isUppercase = true,
    className,
}) =>
    createElement(
        tag,
        {
            className: classNames(
                styles.wrapper,
                className,
                {
                    [styles.style1]: type === '1',
                    [styles.style2]: type === '2',
                    [styles.style3]: type === '3',
                    [styles.style4]: type === '4',
                    [styles.style5]: type === '5',
                    [styles.style6]: type === '6',
                    [styles.style7]: type === '7',
                    // [styles.left]: align === 'left',
                    // [styles.center]: align === 'center',
                    // [styles.right]: align === 'right',
                    // [styles.uppercase]: isUppercase,
                },
                align
            ),
        },
        children
    );

