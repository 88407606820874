.formWrapper {
    border: 1px solid red;
    display: flex;
    width: 100%;
}

.expertsImg {
    width: 100%;
}

.button_inner {
    display: flex;
    align-items: center;
    justify-content: center;
}

.button {
    margin: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    box-shadow: 0px 22px 39px -11px rgba(238, 93, 150, 0.76);
    border-radius: 10px;
}

::placeholder {
    color: #fff;
    opacity: 0.5;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
