import { FC } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { ArrowButton } from '../../../shared/components/Arrow/Arrow';

export type DocumentContent = {
    key: string;
    value: string[];
};

type Props = {
    title: string;
    date: string;
    content: DocumentContent[];
};

export const Documents: FC<Props> = ({ title, date, content }) => {
    return (
        <div className="p-5">
            <div className="max-w-[680px] mx-auto">
                <div className=" justify-center text-center py-3">
                    <span className="text-xl uppercase font-extrabold">ERA</span>
                    <span className="font-light"> crypto</span>
                </div>

                <div>
                    <Link to="/">
                        <ArrowButton direction="left" className="text-[#808897]">
                            BACK
                        </ArrowButton>
                    </Link>
                </div>

                <div className="flex justify-between my-7">
                    <span className="text-3xl">{title}</span>
                    <div>
                        <div className="text-right">
                            <span className="text-[#808897]">Updated on</span>
                        </div>
                        <div className="text-right">
                            <span className="text-[#808897]">{moment(date).format('DD MMMM YYYY')}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col gap-8">
                    {content.map(({ key, value }) => (
                        <div key={key} className="flex flex-col">
                            {key && (
                                <div className="py-2">
                                    <span className="text-[#808897] font-bold">{key}</span>
                                </div>
                            )}

                            <div className="flex flex-col gap-5">
                                {value.map((v, index) => (
                                    <span key={index} className="text-base font-normal">
                                        {v}
                                    </span>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>

                <div className="mt-5">
                    <Link to="/">
                        <ArrowButton direction="left" className="text-[#808897]">
                            BACK
                        </ArrowButton>
                    </Link>
                </div>

            </div>
        </div>
    );
};
