import {FC} from "react";
import cn from 'classnames'

type Props = {
    className?: string
}

export const CircleButton: FC<Props> = ({ children, className }) => {

    return <button className={cn('rounded-full p-5 w-16 h-16', className)}>{children}</button>
}
