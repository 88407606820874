.scrollWrapper {
    display: flex;
    justify-content: center;
}
.scroll {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    width: 115px;
    height: 115px;
    border: 1px solid #fff;
    border-radius: 50%;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    margin-bottom: 36px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.1em;
    line-height: 16px;
    z-index: 2;
}
