.deal {
    display: flex;
    align-items: center;
    margin-bottom: 60px;
    position: relative;
}
.dealTitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    margin-right: 20px;
}
.arrowRight {
    margin-left: 10px;
    cursor: pointer;
}
