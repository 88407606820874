import { FC } from 'react';
import { Title } from '../../../../../shared/components/Title/Title';
import { Slider, SliderFrame } from "../../../../../shared/components/Slider/Slider";
import map from '../../assets/Img/map.svg';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

const sliderFrames = [
    {
        frame: <SliderFrame texts={['ERA Crypto is your clear', 'and countable', 'income from every deal.']} />,
    },
    {
        frame: <SliderFrame texts={['Benefit', 'from the growing community', 'deals']} />,
    },
    {
        frame: <SliderFrame texts={['Influence', 'real market prices']} />,
    },
];

export const GlobalMarket: FC = () => {
    const { isMobile } = useWindowDimensions()
    
    return (
    <div className="mt-24 flex flex-col md:flex-row gap-8 z-10 items-center">
        <div className="max-w-[1108px] max-h-[669px] basis-1/2">
            <img src={String(map)} alt="map-point" />
        </div>

        <div className="basis-1/2">
            <Title type={isMobile ? "3" : "1"} tag="h3">
                Global market <br /> efficiency
            </Title>
            <Slider frames={sliderFrames} />
        </div>
    </div>
)};
