import { FC } from 'react';
import { DocumentContent, Documents } from './Documents';

const documentContent: DocumentContent[] = [
    {
        key: '',
        value: [
            'These Terms of Service (hereinafter referred to as Terms) are meant to establish guidelines for use of the various services offered by ERA crypto (hereinafter referred as ERA crypto, we, us, our) and include both use terms, as well as our privacy policy. You as  the ERA crypto services user (hereinafter referred to as user, you, your, yours) acknowledge that you will be bound by these terms every time you access, download, or use any of the services we offer. Your continued usage of this website will signify your acceptance of all policies listed on this page so please take some time to review them carefully before proceeding further.',
            'By making use of ERA crypto services you acknowledge and agree that: (1) You are aware of the risks associated with transactions of digital currencies and their derivatives; (2) You shall assume all risks related to the use of ERA Crypto services and transactions involving digital currencies and their derivatives; And (3) ERA Crypto shall not be liable for any such risks or adverse outcomes.',
            'YOU CONSENT TO ENTERING THESE TERMS ELECTRONICALLY, AND TO THE STORAGE OF RECORDS RELATED TO THESE TERMS IN ELECTRONIC FORM.',
            'By accessing ERA crypto services in any capacity, you agree to be bound by these Terms. If you do not agree, please refrain from using or trying to use the Service.',
        ],
    },
    {
        key: 'Definitions',
        value: [
            '1. ERA Crypto refers to an ecosystem including web pages and mobile apps created for sale of products offered by ERA Crypto and third parties. The designated website includes third party websites that offer similar services along with content which does not comply with these rules; however, in the event of any inconsistencies between appropriate terms of these websites or applications, the provisions of the designated websites and applications shall prevail.',
            '2. ERA crypto Account refers to any virtual account, which is opened by ERA crypto for a user to maintain their digital footprint through ERA crypto. They serve as the key to access ERA crypto.',
            '3. All parties which run ERA crypto, including but not limited to legal persons (including ERA crypto UAB), unincorporated organizations and teams that provide ERA crypto Services are collectively referred to as ERA Crypto Operators or Operator(s). Wherever possible this document uses ERA Crypto or we when it refers only to Operator(s). We may change as required according to our business needs so this does not affect your rights and interests under these Terms and our involvement continues regardless of changes made. Alternatively new Operator(s) may join if we decide to offer new service offerings. If you continue using ERA Crypto Services it will be deemed you have agreed to jointly execute these terms with the new Parties added. Any dispute shall be determined depending on the specific service provided, who directly executes them with you, and between whom there is disagreement about those particular actions which would affect your rights or interests related to those services',
            '4. ERA crypto Services may refer to various services provided by ERA crypto that are based on internet and/or blockchain technologies and offered via ERA Crypto Websites, Mobile Applications, Clients and other forms (including new ones enabled by future technological developments). ERA Crypto Services include but are not limited to such ERA Crypto Ecosystem Components as Digital Asset Trading Platforms, the financing sector, etc., which will be coming soon!',
            '5. Digital currencies refer to encrypted or digital tokens or cryptocurrencies with a certain value that are based on blockchain and cryptography technologies and are issued and managed in a decentralized form.',
            '6. Users refers to all individuals, institutions or organizations that access, download or use ERA crypto services and who meet the criteria and conditions stipulated by ERA crypto. If there exist other agreements for such entities as developers, distributors, market makers, and Digital Currencies exchanges they will follow those guidelines of those particular agreements.',
        ],
    },
    {
        key: 'General Provisions',
        value: [
            '1. These Terms of Service form a binding contract between you and ERA crypto Operators.',
            '2. By using ERA crypto Services you accept all rapidly changing rights and obligations arising from your country legislation and future development of ERA crypto Services.',
            'If any applicable laws restrict or forbid you from using ERA crypto, you shall follow such restrictions or stop visiting or using ERA crypto.',
            '3. ERA crypto reserves the right to modify these Terms of Service at any time. It will post public notice on its website eracrypto.app and update the date of revision to confirm such changes. Any modification or change shall go into effect upon posting or release for use, and you agree to such updated Agreement and Rules through continued use of ERA crypto Services. If you do not agree with any modifications, you should stop using our Services immediately; it is recommended that you review these Terms of Service often to ensure full understanding of what applies when accessing and using services offered by ERA crypto.',
            '4. By accessing and using Era crypto Services, you represent and warrant that you are not included in any trade embargoes or economic sanctions list (such as the United Nations Security Council Sanctions List), the List of Specially Designated Nationals maintained by OFAC (the Office of Foreign Assets Control of the U.S. Department of Treasury), or Denied Persons or Entity List of the U.S. Department of Commerce. ERA crypto reserves the right to choose markets and jurisdictions to conduct business, and may restrict or refuse, in its discretion, provision of ERA crypto Services in certain countries or regions.',
        ],
    },
    {
        key: 'User’s account',
        value: [
            '1. To use ERA crypto services in full you should sign up with a crypto wallet. Currently ERA crypto services support Solana crypto wallets Phantom and Solflare, however this list as well as the list of supported blockchains can be changed or modified by ERA crypto.',
            '2. By using your wallet you guarantee that you are the owner of the wallet. Please mind that ERA crypto reserves the right to suspend, freeze or cancel the use of any of the accounts if any unauthorized use occurs.',
            '3. If you suspect or become aware of the unauthorized use of your wallet you should immediately notify ERA crypto. In any of the cases ERA crypto bears no responsibility for any losses incurred during the usage of your account by you or any third party with or without your authorization.',
            '4. By using your account you may send, upload, communicate, transmit or provide in other methods, information, data, software, sound, photographs, graphics, video, tags, or other materials ("Content") via ERA crypto. You are exclusively responsible for any and all Content that you may provide via ERA crypto, either posted in public or sent in private. Regarding to such Content, you agree to comply with applicable laws and to the following:',
            "A. You shall not provide any Content,harmful,threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, discriminatory, pornographic, sexually explicit or otherwise;",
            'B. You shall not provide any Content that contains viruses,corrupted data, or other harmful, disruptive, or destructive files;',
            'C. You shall not provide Content that will be made in breach of any legal duty owed to a third party, such as a contractual duty or a duty of confidence;',
            'D. You shall not provide any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;',
            'E. You shall not provide any Content that infringes any patent, trademark, trade secret, copyright or other proprietary rights of any party',
        ],
    },
    {
        key: 'Account Security',
        value: [
            '1. ERA crypto has implemented industry-standard protection for ERA crypto services, however the actions of individual users may pose risks. You will be liable for maintaining confidentiality over your access credentials such as username and password referring to your wallet, and not disclosing this information to any third party. Additionally, you are responsible for taking appropriate security measures to protect your era-crypto account and other data which includes but is not limited to your login details; responsibility lies with you alone to keep these safe from unauthorized intrusion.',
            '2. In order to maintain user privacy while upholding legal obligations, we reserve the right - at our sole discretion - to require user names along with corresponding email address information upon creation or modification of an ERA crypto account if it becomes necessary pursuant to investigations into potential fraudulent activity or otherwise arising out of violations of these Terms of Service or applicable law.',
        ],
    },
    {
        key: 'Account use',
        value: [
            '1. All actions taken while using the ERA Crypto Services must follow both applicable laws and regulations, these Terms and various guidelines of ERA Crypto.',
            "2. Your use of ERA Crypto Services should not violate public interest, morals, or the legitimate interests of others. This includes any action which would interfere with other users' ability to use ERA crypto.",
        ],
    },
    {
        key: 'Deals',
        value: [],
    },
    {
        key: 'Buy orders',
        value: [
            '1. To ensure your Buy order is posted you need to authorise with a Solana wallet.',
            '2. Please ensure that your order contains details on what you need so that sellers could form precise offers.',
            '3. Your Buy order should abide to applicable laws and these Terms and may not contain any illegal requests.',
            '4. ERA crypto reserves the right to delete any Buy order that seems violating the laws or these Terms without any prior notifying.',
            '5. You have the right to respond to Buy orders of other users offering them your goods and services.',
        ],
    },

    {
        key: 'Sell offers',
        value: [
            '1. To ensure your Sell offer is posted you need to authorise with a Solana wallet.',
            '2. Please ensure that your offer contains details on the item or service so that buyers could make fastest decisions.',
            '3. Your Sell offer should abide to applicable laws and these Terms and may not contain any illegal offers',
            '4. ERA crypto reserves the right to delete any Sell offer that seems violating the laws or these Terms without any prior notifying.',
            '5. You have the right buy from Sell offers of other users. To start a deal with your wallet you will need USDT amount and a small amount of Solana crypto currency on your wallet balace to ensure a safe deal. If your wallet does not have sufficient amount to execute an Order, then the deal shall not start.',
        ],
    },

    {
        key: 'Fees',
        value: [
            '1. You agree to pay fees of ERA crypto.',
            '2. The fee for each deal is 3% of the deal amount',
            '3. ERA crypto may, in its discretion, update the fees at any time.',
            '4. Any updated fees will apply to any Deals or other Transactions that occur following the effective date of these Terms. You authorize ERA Crypto to deduct from the deal amount any applicable fees that you owe under these Terms.',
        ],
    },

    {
        key: 'Cancellation',
        value: [
            '1. For Orders initiated through ERA crypto Services, you may only cancel them before they have started.',
            '2. Once your Order has started when you have pressed Start a deal button, you may not change, revoke or cancel the order. ERA crypto reserves the right to reject any cancellation request related to the Order you have submitted',
        ],
    },

    {
        key: 'Partner services',
        value: [
            '1. For your convenience ERA crypto may offer you using ERA crypto partner platforms for exchange, lending and other financial services.',
            '2. While using the platforms hereinabove you solely shall be responsible for your actions and possible violations of those platforms terms. To avoid any violations please be sure to read carefully the platforms terms.',
            '3. ERA crypto may, in its discretion, update partners list at any time.',
        ],
    },

    {
        key: 'Liabilities',
        value: [
            'Without limiting our liabilities expressly set forth herein or required by the applicable laws, you expressly understand and agree that WE SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER LOSSES (EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING FROM: (I) THE INABILITY TO USE ERA CRYPTO, AND/OR RELATED SERVICES; (II) THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH ERA CRYPTO, AND/OR RELATED SERVICES; (III) UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (IV) STATEMENTS OR CONDUCT OF ANY THIRD PARTY IN ERA CRYPTO, AND/OR RELATED SERVICES; OR (V) ANY OTHER MATTER RELATING TO ERA CRYPTO, AND/OR RELATED SERVICES.',
        ],
    },

    {
        key: 'Contact us',
        value: [
            'If you have any further questions these Terms or the privacy note, please contact us via the contact information on eracrypto.app'
        ],
    },
];

export const TermsOfUse: FC = () => {
    return (
        <div className="h-[calc(100%-80px)] overflow-y-auto pb-6">
            <Documents title="TERMS of USE" date="2022-09-15T12:01:33.154Z" content={documentContent} />
        </div>
    );
};
