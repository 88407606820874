import { FC } from 'react';
import * as styles from './Advantages.module.css';

const advantagesData = [
    {
        img: require('../../assets/Img/multi-currency.svg'),
        imgAlt: 'multi-currency-img',
        title: 'Cheap deals',
        text: 'ERA crypto uses the cheapest blockchains in the market for fast and low-costs deals. For stablecoins use ERA crypto partner exchange platforms.',
    },
    {
        img: require('../../assets/Img/safety-reliability.svg'),
        imgAlt: 'safety-reliability-img',
        title: 'Safety & Reliability',
        text: 'ERA crypto does not keep your money. You control your wallet.',
    },
    {
        img: require('../../assets/Img/efficient.svg'),
        imgAlt: 'efficient-img',
        title: 'Earning money',
        text: 'Earn passive income from every trade of your referral. You are welcome to be rich!',
    },
];

export const Advantages: FC = () => (
    <div className="z-10 relative flex flex-col md:flex-row md:mt-80 gap-12">
        {advantagesData.map(({ img, imgAlt, title, text }) => (
            <div key={title} className="md:basis-1/3">
                <div className="flex flex-row md:flex-col gap-3 md:gap-10 mb-10">
                    <img src={img} alt={imgAlt} className="w-[30px] h-[30px] md:w-[55px] md:h-[55px]" />
                    <span className="leading-[44px] tracking-[1px] font-bold text-[30px] md:h-[90px]">{title}</span>
                </div>
                <div className={styles.advantagesText}>{text}</div>
            </div>
        ))}
    </div>
);
