.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    color: #fff;
    cursor: pointer;
    border-radius: 10px;
    border: none;
    outline: none;
}

.sizeS {
    width: 113px;
    height: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
.sizeM {
    width: 162px;
    height: 50px;
}
.sizeL {
    width: 250px;
    height: 70px;
}
.sizeXl {
    width: 335px;
    height: 70px;
}

.sizeFull {
    width: 100%;
    height: 50px;
}

.colorWhite {
    background: #ffffff;
    color: #09131f;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.043);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
.colorGray {
    background: rgba(255, 255, 255, 0.04);
    border: 1px solid #808897;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.colorTransparent {
    background: transparent;
    border: 1px solid #808897;
    color: #808897;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}
