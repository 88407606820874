import { FC } from 'react';
import { DocumentContent, Documents } from './Documents';

const documentContent: DocumentContent[] = [
    {
        key: '',
        value: [
            'This Privacy Notice describes collecting and processing of your Personal Information.',
            'We collect information when you use our services. This includes when you create an account, send messages or make purchases. We also collect information about how you use our services so that we can improve them.',
            'We use this information to provide our services to you. We also use this information to promote products and services which we think will be of interest to you based on your use of our Services, but we do not disclose Personal Information other than as set forth in this Privacy Notice without your consent.',
        ],
    },
    {
        key: 'Information Sharing',
        value: [
            'We may share your Personal Information with third-party vendors who help us with business operations such as billing or providing customer service; however, those vendors do not have any right to use Personal Information for their own purposes or share it with other parties except as permitted by law or with your consent.',
            "We don't share personal information with other companies except when we need to do so to provide the Service(s) or when the law requires us to do so (e.g., court orders or other legal mandates). If we are required by law to disclose Personal Information, then we will make reasonable efforts to notify you.",
            "Your privacy is important to us and we do everything possible to make sure it's safe. We are committed to never sharing any of your personal information without permission from you, unless required by law enforcement or a governmental agency requests it.",
        ],
    },
    {
        key: 'Cookies',
        value: [
            'To make your shopping experience simple and fast ERA crypto uses your wallet details however other partner and analytics services may use cookies to provide understanding and improving user experience.',
            "Depending on which laws apply in the country you reside, your browser's cookie banner will tell you how to either accept or refuse cookies.",
            "We don't share personal information with other companies except when we need to do so to provide the Service(s) or when the law requires us to do so (e.g., court orders or other legal mandates). If we are required by law to disclose Personal Information, then we will make reasonable efforts to notify you.",
            "Your privacy is important to us and we do everything possible to make sure it's safe. We are committed to never sharing any of your personal information without permission from you, unless required by law enforcement or a governmental agency requests it.",
        ],
    },
    {
        key: 'Data transfer',
        value: [
            'ERA crypto may transfer your data outside of the Hong Kong area. ERA crypto puts in place appropriate safeguards to ensure that such transfers are carried out responsibly and compliantly with applicable data protection rules.',
        ],
    },
    {
        key: 'Children',
        value: ['We do not allow anyone under the age of 18 to use ERA crypto Services'],
    },
    {
        key: 'Contact',
        value: [
            'If you have any questions or objections regarding how we collect and process your personal information, please contact us at my@eracrypto.app. You can revoke your consent anytime.',
        ],
    },
    {
        key: 'Amendments',
        value: [
            'If you choose to use these services from ERA crypto, your use and any disputes over privacy are subject to this Notice and our Terms of Use. If you have any concerns about privacy at ERA crypto, please contact us with a thorough description, so we can work on resolving the issue for you.',
            "Our business may change rapidly, which might cause updates for these policies too; make sure that whenever possible you're checking our websites for recent updates - even moreso now than ever before. Remember that by continuing to access these services through ERA crypto after they release an updated version of their Privacy Policy, it means that you agree to abide by their terms and acknowledge the latest policy update. Under no circumstances will our earlier versions (the ones preceeding this most recent update) apply retroactively or beyond those made explicit herein (henceforth)",
        ],
    },
];

export const PrivacyNotice: FC = () => {
    return (
        <div className="h-[calc(100%-80px)] overflow-y-auto pb-6">
            <Documents title="Privacy Notice" date="2022-09-15T12:01:33.154Z" content={documentContent} />
        </div>
    );
};
