import { FC } from 'react';
import * as styles from './Experts.module.css';
import computer from '../../assets/Img/computer.png';
import { Title } from '../../../../../shared/components/Title/Title';
import { Button } from '../../../../../shared/components/Button/Button';
import { Gradient } from '../../../../../shared/components/Gradient/Gradient';
import { redirect } from '../../../../../services/redirect';
import { appUrl } from '../../../../../constants';

export const Experts: FC = () => (
    <div className="relative mt-44">
        <div className="flex flex-col md:flex-row-reverse justify-around">
            <div>
                <img src={String(computer)} alt="computer" className={styles.expertsImg} />
                <Gradient type="2" />
            </div>
            <div>
                <Title type="5">
                    Buy or sell
                    <br />
                    right now
                </Title>
                <div className={styles.button_inner}>
                    <Button colorScheme="gradient" size="L" className={styles.button} onClick={() => redirect(appUrl)}>
                        Start now
                    </Button>
                </div>
            </div>
        </div>
    </div>
);

