.wrapper {
    font-family: 'Gilroy-Medium', sans-serif;
}

.style1 {
    font-size: 48px;
    line-height: 42px;
    margin: 50px 0 40px 0;
    text-align: center;
}

.style2 {
    font-size: 33px;
    line-height: 35px;
    margin-top: 55px;
}

.style3 {
    font-weight: 700;
    font-size: 36px;
    line-height: 40px;
    text-align: center;
    letter-spacing: 1px;
    margin-bottom: 65px;
}

.style4 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
}

.style5 {
    font-size: 64px;
    text-align: center;
    margin-bottom: 50px;
}
.style6 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 48px;
    color: #fff;
}

.style7 {
    font-style: normal;
    font-weight: 700;
    font-size: 144px;
    line-height: 126px;
    color: #fff;
}

