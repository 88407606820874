import { FC } from 'react';
import * as styles from './News.module.css';
import cx from 'classnames'
import { Title } from '../../../../../shared/components/Title/Title';
import { Gradient } from '../../../../../shared/components/Gradient/Gradient';

const newsData = [
    {
        id: 1,
        img: require('../../assets/Img/picDone.svg'),
        alt: 'pic-date',
        date: "jul 22'",
        text: 'ERA crypto 1.0',
        about: 'We are excited to welcome you on board. Thank you for joining us! Feel free to share your referral link and start earning with us today!',
    },
    
    {
        id: 2,
        img: require('../../assets/Img/picDone.svg'),
        alt: 'pic-date',
        date: "mar 23'",
        text: 'ERA crypto 1.6',
        about: `We are happy to announce higher speed and safer deals. Some visual improvements have been made.
        Start your shopping now!`,
    },

];

export const News: FC = () => (
    <div className={cx(styles.news, 'mt-48')}>
        <Gradient type="3" />
        <Title type="1">News</Title>
        <div className={styles.newsBlock}>
            {newsData.reverse().map(({ id, date, img, alt, text, about }) => (
                <div className={styles.newsWrapper} key={id}>
                    <div className={styles.newsData}>
                        <img src={img} alt={alt} />
                        <span className={styles.date}>{date}</span>
                    </div>
                    <div className={styles.newsInfo}>
                        <Title type="4">{text}</Title>
                        <div className={styles.newsText}>{about}</div>
                    </div>
                </div>
            ))}
        </div>
    </div>
);
