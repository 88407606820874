import { FC } from 'react';
import * as styles from './Scroll.module.css';

type Props = {
    handleScroll: () => void
}

export const Scroll: FC<Props> = ({ handleScroll }) => (
    <div className={styles.scrollWrapper} onClick={handleScroll}>
        <div className={styles.scroll}>
            <span>Scroll</span>
        </div>
    </div>
);
