.newExperience {
    padding-bottom: 50px;
    position: relative;
    z-index: 2;
}

.textWrapper {
    /* font-size: 36px; */
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke: 0.3px white;
    padding: 0 40px;
}

.fastSupportive {
    text-align: center;
    padding-left: 18px;
}

.reliableMoney {
    display: flex;
}

.newExperienceText>div:first-child {
    margin: 0 50px;
}

.newExperienceText>div:nth-child(2) {
    margin-left: 20px;
}

.expFast {
    margin-right: 30px;
}

.expMoney {
    margin-left: 30px;
}

.expIntegrated {
    padding-left: 10px;
}

.expForPeople {
    text-align: right;
}

.expTitle {
    /* -webkit-text-stroke: 2px white; */
    -webkit-text-fill-color: white;
    /* font-size: 36px; */
    line-height: 32px;
    margin-left: 70px;
}

.expGradient {
    position: absolute;
    /* top: 31%;
    left: 35%; */
    top: -300%;
    border: 1px solid black;
    filter: blur(30px);
    width: 200px;
    height: 200px;
    z-index: -1;
    background: radial-gradient(52.02% 42.66% at 50.13% 57.65%, rgba(250, 134, 156, 0.9) 0%, rgba(250, 134, 156, 0) 93.16%),
        radial-gradient(41.14% 25.08% at 55.47% 53.63%, rgba(42, 101, 171, 0.9) 0%, rgba(42, 101, 171, 0.623243) 0%, rgba(42, 101, 171, 0) 100%),
        radial-gradient(60.41% 31.11% at 39.47% 63.83%, rgba(237, 31, 154, 0.9) 0%, rgba(237, 31, 154, 0) 95.43%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
        ,
        radial-gradient(70.28% 57.81% at 50.13% 57.65%, rgba(165, 45, 110, 0.9) 0%, rgba(165, 45, 110, 0.429933) 52.23%, rgba(165, 45, 110, 0) 100%), radial-gradient(51.3% 27.2% at 66.13% 58.58%, rgba(62, 36, 170, 0.9) 6.57%, rgba(62, 36, 170, 0) 99.16%)
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    ;
    opacity: 0.9;
}
