import { FC, useRef } from 'react';
import * as styles from './Landing.module.css';
import topdevice from './assets/Img/top-device.png';
import { Advantages } from './components/Advantages/Advantages';
import { Header } from './components/Header/Header';
import { EraAbout } from './components/EraAbout/EraAbout';
import { Scroll } from './components/Scroll/Scroll';
import { WhatIs } from './components/WhatIs/WhatIs';
import { GlobalMarket } from './components/GlobalMarket/GlobalMarket';
import { NewExperience } from './components/NewExperience/NewExperience';
import { CryptoUniverse } from './components/CryptoUniverse/CryptoUniverse';
import { News } from './components/News/News';
// import { Groups } from './components/Groups/Groups';
import { Experts } from './components/Experts/Experts';
import { Footer } from '../../../shared/components/layout/Footer/Footer';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { Socials } from './components/Socials/Socials';
import { SocialsVertical } from './components/Socials/SocialsVertical';

export const Landing: FC = () => {
    const scrollRef = useRef<HTMLDivElement>(null);

    const { isMobile } = useWindowDimensions();

    const handleScroll = () => {
        scrollRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div>
            <div className="max-w-[680px] md:max-w-none md:px-30 lg:px-60 relative">
                {!isMobile && <SocialsVertical />}
                <Header />
                <Scroll handleScroll={handleScroll} />
                <div className="h-[50vh] lg:h-[75vh] flex justify-center">
                    <img src={String(topdevice)} alt="truck-img" />
                </div>
                <div ref={scrollRef} />
                <div className={styles.container}>
                    <EraAbout />
                    <Advantages />
                </div>
                <WhatIs />
                <GlobalMarket />
                <NewExperience />
                <CryptoUniverse />
                <News />
                {/* {!isMobile && <Groups />} */}
                {!isMobile && <Socials />}
                <Experts />
                <Footer />
            </div>
        </div>
    );
};

