import { FC } from 'react';
import * as styles from './Header.module.css';
import { Title } from '../../../../../shared/components/Title/Title';
import { Gradient } from '../../../../../shared/components/Gradient/Gradient';
import useWindowDimensions from '../../../../hooks/useWindowDimensions';

export const Header: FC = () => {
    const { isMobile } = useWindowDimensions()
    
    return (
        <header className={styles.header}>
            <Gradient type="5" />
            <div>
                <span className={styles.title}>era</span>
                <span className={styles.crypto}>crypto</span>
            </div>
            <div className="max-w-[770px] text-center lg:my-16 my-6">
                <Title type={isMobile ? "5" : "7"} tag="h1">
                    A new trade universe
                </Title>
            </div>
        </header>
    );
};

