import { FC } from 'react';
import { Link } from 'react-router-dom';
import whitePaperLink from 'url:../../../../App/components/Landing/assets/White_paper.pdf';
import { SubscriptionForm } from '../../../../App/components/Landing/components/SubscriptionForm/SubscriptionForm';
import useWindowDimensions from '../../../../App/hooks/useWindowDimensions';

export const Footer: FC = () => {
    const { isMobile } = useWindowDimensions()

    return (
        <div className="mt-24 mb-11 flex flex-col gap-11 p-4 md:p-0  md:flex-row-reverse justify-around items-center">
            <div className="w-full">
                <SubscriptionForm />
            </div>

            <div className="flex gap-4 w-full justify-between md:justify-end">
                <Link to="/terms-of-use" className="text-center opacity-50 uppercase text-xs tracking-widest">Terms of Use</Link>
                <a className="text-center opacity-50 uppercase text-xs tracking-widest" href={whitePaperLink} target="_blank" rel="noopener noreferrer" >White Paper</a>
                <Link to="/privacy-notice" className=" text-center opacity-50 uppercase text-xs tracking-widest">Privacy notice</Link>
            </div>
            
            {
                !isMobile && <div className="mb-32" />
            }
        </div>
    );
};
