import redditIconPath from '../../assets/Img/reddit.svg';
import discordIconPath from '../../assets/Img/discord.svg';
import facebookIconPath from '../../assets/Img/facebook.svg';
import telegramIconPath from '../../assets/Img/telegram.svg';
import twitterIconPath from '../../assets/Img/twitter.svg';
import youtubeIconPath from '../../assets/Img/youtube.svg';
import mediumIconPath from '../../assets/Img/medium.svg';

export const socialLinks = [
    {
        name: 'medium',
        image: mediumIconPath,
        link: 'https://link.medium.com/zkiR0lHryxb',
    },

    {
        name: 'reddit',
        image: redditIconPath,
        link: 'https://www.reddit.com/user/eracrypto_app/comments/111v5zz/solana_blockchain_for_fast_and_cheap_deals/?utm_source=share&utm_medium=ios_app&utm_name=iossmf',
    },

    {
        name: 'discord',
        image: discordIconPath,
        link: 'https://discord.gg/JH7Ey8npPW',
    },
    {
        name: 'facebook',
        image: facebookIconPath,
        link: 'https://www.facebook.com/eracryptoapp',
    },
    {
        name: 'telegram',
        image: telegramIconPath,
        link: 'https://t.me/+fF-0VrkrPCdhMmQy',
    },
    {
        name: 'twitter',
        image: twitterIconPath,
        link: 'https://twitter.com/eracrypto_app',
    },
    {
        name: 'youtube',
        image: youtubeIconPath,
        link: 'https://m.youtube.com/@eracryptoapp/shorts',
    },
];