import { FC } from 'react';
import * as styles from './EraAbout.module.css';
import { Title } from '../../../../../shared/components/Title/Title';
import { Deal } from '../Deal/Deal';

export const EraAbout: FC = () => (
    <div className="flex flex-col md:flex-row">
        <div className="basis-2/3">
            <Title type="2" tag="h2" className="md:!text-[75px] md:!leading-[67px] font-bold">
                ERA crypto is<br/>a decentralized<br/>marketplace for<br/>real goods.
            </Title>
        </div>
        
        <div className="basis-1/3">
            <p className={styles.description}>
                No hidden commissions.<br/>
                No scamming.<br/>
                No delays. <br/><br/>
                <b>Reliability. Speed. Real goods.</b>
            </p>
            <Deal />
        </div>
        
    </div>
);
