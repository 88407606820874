.whatIs {
    position: relative;
    z-index: 2;
}
.whatIsText {
    text-align: center;
    padding: 0 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1px;
}

