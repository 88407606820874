.form {
    display: flex;
    /* margin: 95px 15px 45px 15px; */
    border-bottom: 1px solid grey;
}

.input {
    max-width: 585px;
    width: 100%;
    border: none;
    outline: none;
    -webkit-appearance: none;
    vertical-align: middle;
    background: inherit;
    color: #fff;
}


.subscribeBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background: inherit;
    cursor: pointer;
    max-width: 185px;
    width: 100%;
}

.summitInput {
    cursor: pointer;
    width: 120px;
    height: 50px;
    border: none;
    background: inherit;
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
}

::placeholder {
    color: #fff;
    opacity: 0.5;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
}
