import { FC } from 'react';
import * as styles from './CryptoUniverse.module.css';
import { Title } from '../../../../../shared/components/Title/Title';

export const CryptoUniverse: FC = () => (
    <div className="mt-24">
        <div className="flex flex-col md:flex-row items-center">
            <div className="basis-1/2">
                <Title type="1">ERA crypto universe</Title>
            </div>
            <div className="basis-1/2 text-center">
                <span>
                    ERA crypto is an answer to your needs. <br />
                    ERA crypto welcomes your freedom.
                </span>
            </div>
        </div>

        <div className="flex flex-wrap justify-center p-10 md:p-0">
            <div className={styles.cryptoUniverseCircleOverlap}>
                <span>Community</span>
            </div>
            <div className={styles.cryptoUniverseCircleOverlap}>
                <span>Transport</span>
            </div>
            <div className={styles.cryptoUniverseCircleOverlap}>
                <span className="text-center">
                    Crypto
                    <br />
                    exchange
                    <br />
                    platforms
                </span>
            </div>
            <div className={styles.cryptoUniverseCircleOverlap}>
                <span>P2P</span>
            </div>
            <div className={styles.cryptoUniverseCircleOverlap}>
                <span>Stores</span>
            </div>
            <div className={styles.cryptoUniverseCircleOverlap}>
                <span>And more</span>
            </div>
        </div>
    </div>
);
