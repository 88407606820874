import { FC } from 'react';
import { socialLinks } from './links';

const verticalSocials = ['telegram', 'discord', 'twitter']

export const SocialsVertical: FC = () => {

    const shortSocialLinks = Object.fromEntries(
        socialLinks.filter(link => verticalSocials.includes(link.name)).map(link => ([link.name, link.link]))
    )
    
    return (
        <div className="absolute left-0 top-[5%]">
            <div className="flex flex-col gap-16">
                <div className="-rotate-90">
                    <a href={shortSocialLinks.telegram} className="opacity-50 text-sm tracking-widest">Telegram</a>
                </div>
                <div className="-rotate-90">
                    <a href={shortSocialLinks.discord} className="opacity-50 text-sm tracking-widest">Discord</a>
                </div>
                <div className="-rotate-90">
                    <a href={shortSocialLinks.twitter} className="opacity-50 text-sm tracking-widest">Twitter</a>
                </div>
            </div>
        </div>
    );
};
