import { FC } from 'react';
import * as styles from './Deal.module.css';
import { ArrowNav } from '../../../../../shared/components/Arrow/Arrow';
import { appUrl } from '../../../../../constants';

export const Deal: FC = () => (
    <div className={styles.deal}>
        <ArrowNav href={appUrl} >
            <span className={styles.dealTitle}>Shop now</span>
        </ArrowNav>
    </div>
);
