.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 2;
    margin-top: 35px;
}

.title {
    font-style: normal;
    font-weight: 800;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.crypto {
    margin-left: 10px;
    letter-spacing: 1px;
    font-size: 20px;
}
