.topLevelContainer {
    /* font-family: 'Gilroy-Regular', sans-serif; */
}

.wrapper {
    /* max-width: 680px; */
    /* margin: 0 auto; */
}

.topDevice {
    width: 100%;
    /* height: 1171px; */
    height: 100%;
    position: relative;
    top: -20px;
    z-index: 2;
}

.container {
    padding: 0 15px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
}

.arrowLeft {
    margin-right: 10px;
    cursor: pointer;
}

.arrowRight {
    margin-left: 10px;
    cursor: pointer;
}
