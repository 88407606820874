@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading {
  border-radius: 50%;
  border: 0.20rem solid rgba(134, 144, 142, 0.3);
  border-top-color: rgb(158, 172, 171);
  -webkit-animation: spin 1s infinite linear;
  animation: spin 1s infinite linear;
}
